var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('material-card',{attrs:{"width":"98%","icon":"mdi-cellphone-dock","color":"primary","title":_vm.$t(_vm.entity + '.TITLE'),"text":_vm.$t(_vm.entity + '.SUBTITLE')}},[_c('v-data-table',{attrs:{"no-results-text":"No se encontraron resultados","search":_vm.search,"hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"sort-by":"calories","page":_vm.page,"items-per-page":_vm.$store.state.itemsPerPage},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.isSelectorMode)?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","outlined":"","dark":""},on:{"click":function($event){return _vm.$router.push({ name: 'MarketingSegments' })}}},[_vm._v("Ver todos los segmentos")]):_vm._e(),_c('v-dialog',{attrs:{"max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.isSelectorMode)?_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.rolPermisos['Write']),expression:"rolPermisos['Write']"}],staticClass:"mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v(_vm._s(_vm.$t(_vm.entity + ".NEW_ITEM")))]):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('MarketingSegmentsForm',{attrs:{"editedIndex":_vm.editedIndex,"editedItem":_vm.editedItem,"odooValues":_vm.odooValues},on:{"onClose":function($event){_vm.dialog = false},"onSave":_vm.onSave}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('span',[_c('strong',[_vm._v("Mostrando:")]),_vm._v(" "+_vm._s(_vm.$store.state.itemsPerPage > _vm.items.length ? _vm.items.length : _vm.$store.state.itemsPerPage)+" de "+_vm._s(_vm.items.length)+" registros ")])])],1)],1)]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.rolPermisos['Edit'] && !_vm.isSelectorMode)?_c('v-btn',{staticClass:"mr-1 mb-1",attrs:{"color":"primary","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(!_vm.isSelectorMode)?_c('v-btn',{staticClass:"mr-1",attrs:{"color":"error","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e(),(!_vm.isSelectorMode)?_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"secondary","loading":_vm.loadingDownloadCsv},on:{"click":function($event){return _vm.downloadCsv(item)}}},[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e(),(_vm.isSelectorMode)?_c('v-btn',{attrs:{"small":"","outlined":"","color":"secondary"},on:{"click":function($event){return _vm.$emit('onSelectedSegment', item)}}},[_vm._v("Previsualizar")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"type":"error","value":true}},[_vm._v(_vm._s(_vm.$t(_vm.entity + ".NO_DATA")))])]},proxy:true},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"format-breaklines"},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatDate")(item.updatedAt)))]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_c('v-btn',{attrs:{"color":"primary","small":""}},[_vm._v("Visitar")])],1)]}},{key:"item.attributes",fn:function(ref){
var item = ref.item;
return _vm._l((item.attributes),function(attribute,attIndex){return _c('ul',{key:attIndex},[_c('li',[_c('b',[_vm._v(_vm._s(attribute.name)+": ")]),_vm._v(_vm._s(attribute.options.join(","))+" ")])])})}},{key:"item.categories",fn:function(ref){
var item = ref.item;
return _vm._l((item.categories),function(category,cattIndex){return _c('ul',{key:cattIndex},[_c('li',[_vm._v(_vm._s(category.name))])])})}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('v-chip',{attrs:{"color":"success"}},[_vm._v("Activo")]):_c('v-chip',{attrs:{"color":"error"}},[_vm._v("Inactivo")])]}}],null,true)}),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('span',[_c('strong',[_vm._v("Mostrando:")]),_vm._v(" "+_vm._s(_vm.$store.state.itemsPerPage > _vm.items.length ? _vm.items.length : _vm.$store.state.itemsPerPage)+" de "+_vm._s(_vm.items.length)+" registros ")])]),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }